import $ from "jquery";

export const initFaqFilter = () => {
    const filterCategories = $("#filter__for-categories");
    const filterSearch = $("#filter__for-search");
    const filterSpecificInfo = $("#filter__for-specific-info");
    const filterSearchIcon = $("#filter-search-icon");

    const filterFaqElements = (categoryValue, infoValue, searchWord) => {
        const handorgelItems = $("#handorgel1");
        let matchCount = 0;

        handorgelItems.children().each(function() {
            let searchMatch = true;

            if (searchWord) {
                let title = $(this)
                    .find(".handorgel__title")
                    .html();
                let content = $(this)
                    .find(".handorgel__content")
                    .html();

                if (
                    title.toLowerCase().includes(searchWord) ||
                    content.toLowerCase().includes(searchWord)
                ) {
                    searchMatch = true;
                } else {
                    searchMatch = false;
                }
            }

            if (categoryValue === "all" && infoValue === "all" && !searchWord) {
                $(this).removeClass("faq__hidden");
            } else {
                let itemClass = $(this).attr("class");
                if (
                    searchMatch &&
                    ((itemClass.includes("faq-category__" + categoryValue) &&
                        "all" === infoValue) ||
                        (itemClass.includes("faq-category__" + categoryValue) &&
                            itemClass.includes("faq-info__" + infoValue)) ||
                        ("all" === categoryValue &&
                            itemClass.includes("faq-info__" + infoValue)) ||
                        ("all" === categoryValue && "all" === infoValue))
                ) {
                    matchCount++;
                    $(this).removeClass("faq__hidden");
                } else {
                    $(this).addClass("faq__hidden");
                }
            }
        });

        if (categoryValue === "all" && infoValue === "all" && !searchWord) {
            $("#faq-found-results").addClass("faq__found-results--hidden");
        } else {
            $("#faq-found-result-text").html(matchCount);
            $("#faq-found-results").removeClass("faq__found-results--hidden");

            if (1 === matchCount) {
                $("#faq-found-plural").addClass("faq__found-results--hidden");
                $("#faq-found-single").removeClass(
                    "faq__found-results--hidden",
                );
            } else {
                $("#faq-found-single").addClass("faq__found-results--hidden");
                $("#faq-found-plural").removeClass(
                    "faq__found-results--hidden",
                );
            }
        }
    };

    filterCategories.change(event => {
        let categoryValue = event.target.value;
        let infoValue = filterSpecificInfo.children("option").attr("value");
        let searchValue = filterSearch.value;
        filterFaqElements(categoryValue, infoValue, searchValue);
    });

    filterSpecificInfo.change(event => {
        let categoryValue = filterCategories.children("option").attr("value");
        let infoValue = event.target.value;
        let searchValue = filterSearch.value;
        filterFaqElements(categoryValue, infoValue, searchValue);
    });

    filterSearch.change(event => {
        let categoryValue = filterCategories.children("option").attr("value");
        let infoValue = filterSpecificInfo.children("option").attr("value");
        filterFaqElements(
            categoryValue,
            infoValue,
            event.target.value.toLowerCase(),
        );
    });

    filterSearchIcon.click(() => {
        let categoryValue = filterCategories.attr("value");
        let infoValue = filterSpecificInfo.attr("value");
        let searchValue = filterSearch.attr("value");
        filterFaqElements(categoryValue, infoValue, searchValue);
    });
};
