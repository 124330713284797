import Glide from "@glidejs/glide";

/**
 * Initialize functionality of gb-carousel slider
 */
export const initGlideCarousel = () => {
    const settings = {
        type: "carousel",
        rewind: false,
        focusAt: "center",
        startAt: 0,
        perView: 1,
    };
    const sliders = document.querySelectorAll(".glide-carousel");
    sliders.forEach(item => {
        new Glide(item, settings).mount();
    });
}

export const initGlideSlider = () => {
    const sliderSettings = {
        type: 'slider',
        perView: 4,
        startAt: 0,
        gap: 25,
        focusAt: 0,
        breakpoints: {
        800: {
            perView: 3,
            perView: 3
        },
        480: {
            perView: 1
        }
        }
    }

    const sliders = document.querySelectorAll(".glide-slider");
    sliders.forEach(item => {
        const glide = new Glide(item, sliderSettings);
        let slideCount = item.getAttribute('data-slidecount');
        glide.on('run', function (e) {
            if (e.direction === '>' && glide.index > slideCount - glide.settings.perView) {
                glide.index = 0;
                glide.update();
            } 
        });
        glide.mount()

        function isMobile() {
            return window.innerWidth <= 575.98;
        }
        if (!isMobile()){
            if (slideCount <= 4 ) {
                item.getElementsByClassName('glide__arrows')[0].style.display = "none";
            } else {
                item.getElementsByClassName('glide__arrows')[0].style.display = "";
            }
        }
    });
    
    const img = document.querySelectorAll('.db-slider-slide__image')[0];
    const computedImgStyle = window.getComputedStyle(img);
    const imgHeight = parseFloat(computedImgStyle.height);

    const arrows = document.querySelectorAll('.glide__arrow');
    arrows.forEach(arrow => {
        arrow.style.height= imgHeight + "px";
        arrow.style.top= (imgHeight/2) + "px";
    });
}
  