import $ from "jquery";
import Cookies from "js-cookie";

export const initDisruptionNotification = () => {
    const header = document.querySelector("header.header");

    $.post(getRestUrl() + "v2/disruption-notification", data => {
        if (data) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(data, "text/html");
            const sections = parsedHtml.querySelectorAll(
                ".s-disruption-notification",
            );

            Array.from(sections).map(notification => {
                const notificationId = notification.dataset.id;

                if (!notificationClosed(notificationId)) {
                    header.insertBefore(notification, header.childNodes[0]);
                    header.classList.add("header--disruption");

                    notification.querySelector(
                        ".s-disruption-notification__close",
                    ).onclick = () => {
                        closeNotification(notification, header, notificationId);
                    };
                }
            });
        }
    });
};

/**
 * Close notification
 *
 * @param container
 * @param header
 * @param notificationId
 */
function closeNotification(container, header, notificationId) {
    let currentlyClosed = Cookies.get("closedDisruptions");

    if ("undefined" === typeof currentlyClosed) {
        currentlyClosed = [];
    } else {
        currentlyClosed = JSON.parse(currentlyClosed);
    }

    // Check if user has closed notification.
    if (!currentlyClosed.includes(notificationId)) {
        currentlyClosed.push(notificationId);
        Cookies.remove("closedDisruptions");
        Cookies.set("closedDisruptions", JSON.stringify(currentlyClosed), {
            expires: 7,
        });

        header.removeChild(container);
        header.classList.remove("header--disruption");
    }
}

/**
 * Check if notification is closed
 *
 * @param notificationId
 * @returns {boolean}
 */
function notificationClosed(notificationId) {
    let currentlyClosed = Cookies.get("closedDisruptions");

    if ("undefined" !== typeof currentlyClosed) {
        if (currentlyClosed.includes(notificationId)) {
            return true;
        }
    }

    return false;
}

/**
 * Get admin url from data-admin_url
 *
 * @returns {string}
 */
function getRestUrl() {
    const urlDiv = document.querySelector("[data-rest_url]");

    if (urlDiv) {
        return urlDiv.dataset.rest_url;
    }

    return "";
}
