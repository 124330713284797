
export const initVideoTriggers = () => {

    let videoOpen = false;
    let videoIframe = false;
    let videoSource = false;
  
    if (document.querySelectorAll(".video").length > 0) {
      document.querySelectorAll(".video").forEach(function (video) {
        const trigger = video.querySelector('.video__trigger');
        
        trigger.addEventListener("click", function (e) {
          e.preventDefault();
          videoOpen = true;
          document.body.classList.add("video-open");
          video.classList.add("video--open");
          const videoLocation = window.scrollY + video.getBoundingClientRect().top + (video.innerHeight/2)
          window.scrollTo({
          top: videoLocation,
          left: 0,
          behavior: "smooth" });
        });
    
        video.querySelector(".video__bg").addEventListener("click", function (e) {
          e.preventDefault();
          const video = document.querySelector(".video--open");
          if(video) {
            resetVideoPreview(video);
          }
        });
      });
    }
  
    window.addEventListener("scroll", function () {
      const video = document.querySelector(".video--open");
      if (video) {
        const triggerPosTop = video.offsetTop;
        const triggerPosBtm = video.offsetTop + this.document.querySelector(".video").offsetHeight;
        if (window.scrollY > triggerPosBtm || window.scrollY + this.window.innerHeight < triggerPosTop) {
          resetVideoPreview(video);
        }
      }
    });
  
    window.addEventListener("resize", function () {
      const video = document.querySelector(".video--open");
      if (video) {
        resetVideoPreview(video);
      }
    });

    const resetVideoPreview = (video) => {
      videoOpen = false;
      video.querySelector(".video__embed").style = "";
      video.classList.remove("video--open");
      document.body.classList.remove("video-open");
      videoIframe = video.querySelector(".video__embed iframe");

      if (videoIframe.hasAttribute("src")) {
        videoSource = videoIframe.getAttribute("src");
        videoIframe.setAttribute("src", videoSource);
      }
    }
};

