var breakpoint = 1080;

document.querySelector( '.nav-toggle' ).addEventListener( 'click', function () {
    document.body.classList.toggle( 'nav-open' );
} );

var toggleBtns = document.querySelectorAll( '.main-nav .toggle' );
toggleBtns.forEach( ( toggleBtn ) => {
    toggleBtn.addEventListener( 'click', function ( e ) {
        e.preventDefault();
        var parentLi = this.parentElement;
        var parentUl = parentLi.parentElement;
        if ( parentUl.querySelectorAll( 'li.active' ).length > 0 ) {
            parentUl.querySelectorAll( 'li.active' ).forEach( ( activeLi ) => {
                if ( this.parentElement !== activeLi ) {
                    activeLi.classList.remove( 'active' );
                }
            } );
        }

        if ( parentLi.classList.contains( 'active' ) ) {
            parentLi.classList.remove( 'active' );
        } else {
            parentLi.classList.add( 'active' );
        }

    } );
} );

document.querySelector( '.nav-shadow' ).addEventListener( 'click', function () {
    document.body.classList.remove( 'nav-open' );
    //document.querySelectorAll( '& > li.active' ).forEach( ( activeLi ) => {
} );

window.addEventListener( 'scroll', function () {
    if ( window.pageYOffset > window.innerHeight ) {
        document.body.classList.remove( 'nav-open' );
    }
} );


// import $ from "jquery";

// import { FocusTrap } from "@valu/focus-trap";

/*
export const initToggleNav = () => {
    const $nav_wrapper = $(".header-primary-nav-wrapper");
    const $hamburger = $(".hamburger");
    const $exit = $(".exit-button");
    const $overlay = $(".primary-nav-open-overlay");
    const $body = $("body");
    const trap = new FocusTrap({
        containers: document.querySelectorAll("#valu-navigation"),
    });
    $hamburger.on("click", () => {
        $nav_wrapper.addClass("open");
        $body.addClass("primary-nav-open");
        trap.enable();
    });

    $exit.on("click", () => {
        closeMenu($body, $nav_wrapper);
        trap.disable();
    });

    $overlay.on("click", () => {
        closeMenu($body, $nav_wrapper);
        trap.disable();
    });
};

function closeMenu($body, $nav_wrapper) {
    if (!$body.hasClass("primary-nav-open")) {
        $body.addClass("primary-nav-closing");
        setTimeout(() => {
            $body.removeClass("primary-nav-closing");
        }, 299);
    }
    $nav_wrapper.removeClass("open");
    $body.removeClass("primary-nav-open");
}
*/
/*
export const initToggleSubMenu = () => {
    const $sub_menu_toggle = $('[data-header-toggle="sub-menu"]');

    // Toggle sub menu
    $sub_menu_toggle.on("click", e => {
        const $self = $(e.currentTarget);

        $self.toggleClass("open");

        const $sub_menu = $self.parent().children(".header-primary-nav-lvl");
        $sub_menu.toggleClass("open");

        $self.attr("aria-expanded", (i, attr) => {
            return attr === "true" ? "false" : "true";
        });
    });
};
*/
