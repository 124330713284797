export const scrollToHash = () => {
    if ("scrollRestoration" in history) {
        // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
        history.scrollRestoration = "manual";
    }

    // Ignore archive pages
    if (location.hash.startsWith("#/")) {
        return;
    }

    const element = location.hash
        ? document.querySelector(location.hash)
        : null;
    const header = document.querySelector("header.header");

    let headerHeight = 0;
    if (header) {
        headerHeight = header.offsetHeight;
    }

    if (element) {
        window.scroll({
            top: getOffsetTop(element) - headerHeight - 10,
            behavior: "smooth",
        });
    }
};

// Gets top offset properly.
const getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
};
