/**
 * Handling the situations when the visitor does not accept cookies. 
 * This will instead notify user that he/she needs to accpet cookies to be able to see the video. 
 * Done via javascript to handle iframe videos added via wysiwyg.
 */

export const cookieConsentHandler = () => {
  if (Cookiebot) {
    let youtubeIframes = document.querySelectorAll('iframe[src*="youtube.com"], iframe[src*="youtu.be"]');
    const currentUrl = window.location.href;

    if (youtubeIframes.length > 0) {
      youtubeIframes.forEach(function (iframe) {

          if (!iframe.nextElementSibling || !iframe.nextElementSibling.classList.contains('cookieconsent-optout-marketing')) {
              const newDiv = document.createElement('div');
              if(currentUrl.includes("/en/")){
                newDiv.innerHTML = '<div class="centered-element bg-light w-100 h-100 p-3 d-flex justify-content-center align-items-center align-middle">Please <a href="javascript:Cookiebot.renew()">accept marketing-cookies</a> to watch this video.</div>';
              } else {
                newDiv.innerHTML = '<div class="centered-element bg-light h-100 p-3 d-flex justify-content-center align-items-center align-middle">Hyväksy <a href="javascript:Cookiebot.renew()">evästeet</a> katsoaksesi tämän videon.</div>';
              }
              newDiv.classList.add('h-100','cookieconsent-optout-marketing');
              iframe.parentNode.insertBefore(newDiv, iframe.nextSibling);
          }
      });
    }
  }
}