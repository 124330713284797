// Import js-packages from node_modules
import $ from "jquery";
import Cookies from "js-cookie";

export const initChatBot = () => {
    $(document).ready(() => {
        const $closeBtn = $("#webchat-close-chat");
        const $chatElement = $("#bot");
        const $chatToggle = $(".b-web-chat-bot-toggle");
        const botID = document.getElementById("webchat-bot-id").innerHTML;

        // Close chat element and set cookie to hide it
        $closeBtn.on("click", () => {
            $chatElement.hide();
            $chatToggle.show();
            Cookies.set(botID, "hide", { expires: 365 });
        });

        // Show chat and update cookie to show it
        $chatToggle.on("click", () => {
            $chatElement.show();
            $chatToggle.hide();
            Cookies.set(botID, "show", { expires: 365 });
        });

        // Check if chat element should be shown, based on cookie
        if (Cookies.get(botID) === "hide") {
            $chatElement.hide();
            $chatToggle.show();
        } else {
            $chatElement.show();
            $chatToggle.hide();
        }
    });
};
