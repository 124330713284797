import $ from "jquery";
import handorgel from "handorgel";

export const initHandorgelAccordion = () => {
    var accordions = Array.from(document.querySelectorAll(".handorgel"));
    accordions = accordions.map(accordion => {
        return new handorgel(accordion, {
            multiSelectable: false,
            collapsible: true,
            headerOpenClass: "handorgel__header--open",
            contentOpenClass: "handorgel__content--open",
        });
    });

    $(document).ready(() => {
        const faqHandorgel = $("#handorgel1");
        let counter = 0;
        let headerClasses = [];
        faqHandorgel.children().each(function() {
            let itemClass = $(this).attr("class");

            $(this).addClass("handorgel__fold--" + counter);

            //if (itemClass.includes("faq__header-wrapper")) {
            if (itemClass.includes("handorgel__header")) {
                let headerClass = itemClass;
                headerClass = headerClass.replace("faq__header-wrapper", "");
                headerClass = headerClass.replace("handorgel__header", "");

                headerClasses.push(headerClass.trim());
            }

            //if (itemClass.includes("faq__content-wrapper")) {
            if (itemClass.includes("handorgel__content")) {
                counter++;
            }
        });

        if (counter > 0) {
            for (let i = 0; i < counter; i++) {
                let elements = $(".handorgel__fold--" + i.toString());

                let newWrapperClass = document.createElement("div");
                newWrapperClass.className =
                    "handorgel__item-wrapper " + headerClasses[i];

                elements.wrapAll(newWrapperClass);
            }
        }
    });

    accordions.map(accordion => {
        accordion.on("fold:open", fold => {
            const foldHeader = fold.header;
            const foldButton = fold.button;

            const foldParentElement = foldHeader.parentElement;

            foldButton.classList.remove("faq__toggler-wrapper--plus");
            foldButton.classList.add("faq__toggler-wrapper--minus");

            foldParentElement.classList.add("handorgel__item-wrapper--open");
            foldParentElement.classList.add("handorgel__content-wrapper--open");
        });

        accordion.on("fold:closed", fold => {
            const foldHeader = fold.header;
            foldHeader.classList.remove("handorgel__header--closing");
        });

        accordion.on("fold:close", fold => {
            const foldHeader = fold.header;
            const foldButton = fold.button;

            foldHeader.parentElement.classList.remove(
                "handorgel__item-wrapper--open",
            );
            foldHeader.parentElement.classList.remove(
                "handorgel__content-wrapper--open",
            );

            foldButton.classList.add("faq__toggler-wrapper--plus");
            foldButton.classList.remove("faq__toggler-wrapper--minus");
            foldHeader.classList.add("handorgel__header--closing");
        });
    });
};
