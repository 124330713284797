// Import js-packages from node_modules
import $ from "jquery";
import "no-scroll";
import "what-input";

// Import js-packages from theme
// import { initToggleNav } from "./lib/header.js";
//import { initOverlayDarkener } from "./lib/darkener.js";
import { initDisruptionNotification } from "./lib/disruptionNotification.js";
import { initAccordion } from "./accordion.js";
import { initGlideCarousel, initGlideSlider } from "./lib/gb-carousel.js";

import { initToggleNav, initToggleSubMenu } from "./lib/header.js";
import { initLazyload } from "./lib/lazyload.js";
import { scrollToHash } from "./lib/scrollToHash";
import { initChatBot } from "./lib/webChatBot.js";
import { cookieConsentHandler } from "./lib/cookieConsentHandler.js";
import { initVideoTriggers } from "./lib/videopalikka.js";

$(document).ready(() => {
    // Lazy load
    initLazyload();
    initVideoTriggers();
    // Initialize mobile navigation
    // initToggleNav();
    //initOverlayDarkener();
    //initToggleNav();
    //initToggleSubMenu();
    scrollToHash();

    // youtube cookieconsent handler
    cookieConsentHandler();

    if ($(".handorgel").length > 0) {
        initAccordion();

        /*
        import("./lib/handorgel").then(module => {
            module.initToggleHangorgel(".handorgel");
        });
        */
    }
    setTimeout(
        function() {
            if ($(".glide-carousel").length > 0) {
            initGlideCarousel();
            }
            if ($(".glide-slider").length > 0) {
                initGlideSlider();
            }
        }, 100
    );
    
    initDisruptionNotification();

    if ($("#bot").size() > 0) {
        initChatBot();
    }

    // $('#lang-selector').on( "mouseenter", function() {
    //     $( "#lang-options" ).addClass('is-active');
    //   } );
    $('#lang-selector').on( "click", function() {
        $( "#lang-options" ).toggleClass('is-active');
      } );
    // $('#lang-options').on( "mouseleave", function() {
    //     $( "#lang-options" ).removeClass('is-active');
    //   } );
    // $(document).on( "click", function() {
    //     $( "#lang-options" ).hide();
    //   } );
});

// Back to top smooth scroll.
/*
$( ".back-to-top__container" ).on( "click keypress", e => {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "300");
});
*/