import $ from "jquery";
import { initHandorgelAccordion } from "./lib/handorgelAccordion.js";
import { initFaqFilter } from "./lib/faqFilter.js";

export const initAccordion = () => {
    initSelection();
    initHandorgelAccordion();
    initFaqFilter();
};

export const initSelection = () => {
    let faqSelection = $(".filter-choices-single-select");

    if (faqSelection.size() > 0) {
        import("./lib/faqSelection").then(module => {
            module.faqCreateSelection(faqSelection);
        });
    }
};
